<template>
  <div class="container mt-5 mb-md-4 py-5">
    <breadcrumb class="mb-3 mb-md-4 pt-md-3" :crumbs="crumbs"></breadcrumb>

    <!-- Page content -->
    <div class="row justify-content-center pb-sm-2 pt-3">
      <div class="col-lg-6">
        <h1 class="h2 text-center pb-4 mb-3">Which account would you like to use?</h1>

        <!-- Is Loading -->
        <div v-if="isLoading" class="mt-3 text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- User Roles -->
        <div v-if="!isLoading">
          <user-role-card v-for="(userRole, i) in userRoles" :key="i"
                          class="mb-3"
                          @selected="selectUserRole(userRole)"
                          :user-role="userRole"></user-role-card>
        </div>
        <!-- / User Roles -->
      </div>
    </div>
    <!-- / Page content -->
  </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb";
import {mapActions, mapGetters} from "vuex";
import UserRoleCard from "./SelectUserRole/UserRoleCard";
import Spinner from "../components/Spinner";

export default {
  name: "SelectUserRole",
  components: {UserRoleCard, Breadcrumb, Spinner},
  computed: {
    ...mapGetters('auth', {
      userRoles: 'userRoles',
      isLoading: 'isLoading',
      userRole: 'userRole'
    })
  },
  created() {
    // this.loadUserRoles().then(() => {
    // if (this.userRole) {
    //   if (this.$route.query.to) {
    //     this.$router.push(this.$route.query.to);
    //   } else {
    //     this.$router.push('/');
    //   }
    // }
    // });
    this.loadUserRoles();
  },
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Select User Account',
          active: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('auth', {
      setUserRole: 'setUserRole',
      loadUserRoles: 'loadUserRoles'
    }),

    selectUserRole(role) {
      this.setUserRole(role);
      if (this.$route.query.to) {
        this.$router.push(this.$route.query.to);
      } else {
        this.$router.push('/');
      }
    }
  }
}
</script>

<style scoped>

</style>